
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        


















































































































































































































































.phone-buttons-group {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  margin-bottom: 6rem;
}

.phone-button {
  display: flex;
  gap: 1.2rem;
  align-items: center;

  &.checkbox {
    width: unset;
  }

  &.disabled {
    pointer-events: none;
  }

  ::v-deep {
    .selectable-block__check {
      order: 0;
      margin: 0;
      border-radius: 50%;

      &.is-selected {
        background-color: $c-anthracite-light;
      }
    }

    .selectable-block__check__icon.icon {
      width: 1.3rem;
    }

    .icon {
      order: 1;
      width: 3rem;
      height: 3.2rem;
    }

    // label, element has no classname
    span {
      order: 3;
      color: $c-bunting;
      font-size: 2.2rem;
      font-weight: 700;
      line-height: 1;
    }
  }
}

.mobile-promo-optin {
  max-width: 86.7rem;
  padding-block: 7rem;

  &.success {
    max-width: unset;

    .phone-buttons-group {
      margin-block: 6rem;
    }

    .phone-button {
      pointer-events: none;

      ::v-deep {
        /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
        .selectable-block__check {
          background-color: transparent;
        }

        /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
        .selectable-block__check__icon.icon {
          width: 2rem;
          fill: $c-green-light;
        }
      }
    }
  }

  @include mq($from: l) {
    padding-block: 16rem 18rem;
  }
}

.benefits {
  @extend %list-nostyle;
}

.benefits__list-item:not(:last-child) {
  margin-bottom: 0.4rem;
}

.benefits__icon {
  vertical-align: text-bottom;
  fill: $c-green-light;
}

.submit {
  margin-top: 6rem;

  &.loading {
    cursor: wait;
    pointer-events: none;

    /* stylelint-disable-next-line no-descending-specificity */
    ::v-deep {
      .btn__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        margin: 0;
        translate: -50% -50%;

        /* stylelint-disable-next-line max-nesting-depth */
        svg {
          width: 2rem;
          height: 2rem;
          animation: spin 1s linear infinite;
        }
      }

      .btn__link__title {
        opacity: 0;
      }
    }
  }
}

.error {
  padding: 1rem;
  color: $c-dev-error;
  /* stylelint-disable-next-line scss/no-global-function-names */
  background-color: transparentize($color: $c-dev-error, $amount: 0.9);
  border: 0.1rem currentcolor solid;
  border-radius: 0.5rem;
}

@keyframes spin {
  from {
    rotate: 0deg;
  }

  to {
    rotate: 360deg;
  }
}
